import { useEffect, useMemo } from "react";
import { useAppSelector } from "../../Store";
import { allMessagesStore } from "../../Store/Slices/socket/AllMessagesSlice";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { deviceSizeStore } from "../../Store/Slices/socket/DeviceSizeSlice";
import { StyledChatUIBoxWrap } from "../Styles/StyledScreens";
import AppChatScreenWrapper from "../appComponents/UtilsComponents/AppChatScreenWrapper";
import useMessagesData from "../hooks/useMessagesData";
import { PreviewType } from "../utils/Enum";
import AppInputHandler from "./AppInputHandler";
import Header from "./Header";
import WidgetCopyright from "./WidgetCopyright";
import AppChatLoadingScreen from "../appComponents/UtilsComponents/AppChatLoadingScreen";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";

interface Props {
  isFullScreen?: boolean;
}

const MainUI: React.FC<Props> = ({ isFullScreen }) => {
  const { chatOpened, widgetPreviewType } =
    useAppSelector(botInfoStore);
  const { height, width } = useAppSelector(deviceSizeStore);
  const { sessionId, allMessages } =
    useAppSelector(allMessagesStore);
  //custom hooks
  const {
    getNewMessages,
    getHistoryMessages,
    loading,
    getNewMessagesForDirectLivechat,
    requestParams,
  } = useMessagesData();

  const { botConfig } = useAppSelector(useBotConfigStore);

  const conversationLimit = useMemo(
    () => botConfig?.config?.conversation,
    [botConfig]
  );
  const directLiveChatEnabled = useMemo(
    () =>
      botConfig?.chatbot?.hasOwnProperty("bot_enabled")
        ? !botConfig.chatbot.bot_enabled
        : false,
    [botConfig]
  );

  // ********************************************************

  // ─── REQUEST DATA EVENTS ──────────────────────────────────────────────────────────────

  // ********************************************************

  const getMessages = () => {
    const isOldVisitor =
      sessionId?.length > 0 &&
      widgetPreviewType !== PreviewType.getaWidgetPreview;

    if (isOldVisitor) {
      getHistoryMessages(requestParams);
    } else if (directLiveChatEnabled) {
      getNewMessagesForDirectLivechat({
        ...requestParams,
        session_id: null,
        bot_enabled: false,
      });
    } else {
      //get new messgaes
      getNewMessages({ ...requestParams, session_id: null });
    }
  };

  useEffect(() => {
    if (!chatOpened) return;
    if (!conversationLimit?.enabled) return;
    if (allMessages?.length > 0) return;

    getMessages();
  }, [chatOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const frameWidth = useMemo(() => {
    return botConfig?.config?.custom_width ? botConfig?.config?.custom_width : width
   }, [width,botConfig]);

  const frameHeight = useMemo(() => {
    const autoHeight = botConfig?.config?.auto_height ?? false;
    const modifiedFinalValue = height - (botConfig?.config?.auto_height_limiting_number ?? 0); 
    const maxHeight = autoHeight ? modifiedFinalValue : 610;
    return maxHeight ?? 610;
   }, [height,botConfig]);

  return (
    <StyledChatUIBoxWrap
      chatopened={chatOpened ? 1 : 0}
      devicewidth={frameWidth}
      deviceheight={frameHeight - 78} // 78 is the height of the header
      isFullScreen={isFullScreen}
    >
      <Header />
      <AppChatLoadingScreen loading={loading} />
      <AppChatScreenWrapper />
      <AppInputHandler />
      <WidgetCopyright />
    </StyledChatUIBoxWrap>
  );
};

export default MainUI;
