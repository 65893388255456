import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Store";
import {
  allMessagesStore,
  removeMessage,
  setAllHistoryMessages,
  setAllMessages,
  setIsSessionIdFromLocalStorage,
  setLastNodeResponse,
  setOriginalResponses,
  setSessionId,
  setTypingStatus,
} from "../../Store/Slices/socket/AllMessagesSlice";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  generateConnectSocketToAgent,
  generateOutsideHoursMessage,
  isWithinBusinessHour,
  sleep,
} from "../utils/utils";
import { PreviewType } from "../utils/Enum";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { HostWindowCommService } from "../../Services/IframeHostService/HostWindowCommService";
import { OEvents } from "../../Services/IframeHostService/models";
import { ConversationService } from "../../Services/Apis";
import { socketStatusStore } from "../../Store/Slices/socket/SocketSlice";

let audio: any = new Audio(require("../../assets/tong.mp3"));
const SLEEP_TIME = 500;

const useMessagesData = () => {
  const commService = useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  //redux
  const dispatch = useAppDispatch();
  const { botConfig } = useAppSelector(useBotConfigStore);

  const { botId, visitorId, widgetPreviewType, getaHost, chatbotParams } =
    useAppSelector(botInfoStore);
  const {
    executeConnectSocketEventWithReConnectUser,
    socket,
    executeConnectSocketEventWithConnectUser,
  } = useAppSelector(socketStatusStore);
  const { sessionId, botLanguage, liveChatConnected } =
    useAppSelector(allMessagesStore);
  const [loading, setLoading] = useState(false);

  const directLiveChatEnabled = useMemo(
    () =>
      botConfig?.chatbot?.hasOwnProperty("bot_enabled")
        ? !botConfig.chatbot.bot_enabled
        : false,
    [botConfig]
  );

  const botName = useMemo(() => botConfig.chatbot?.name ?? "Bot", [botConfig]);
  const botHeaders = useMemo(
    () => ({
      appid: JSON.stringify({
        botid: botId,
      }),
      "geta-host": getaHost,
      visitor_id: visitorId || null,
      preview:
        widgetPreviewType === PreviewType.getaWidgetPreview ? true : false,
      connection_type: "user",
    }),
    [botId, visitorId, widgetPreviewType, getaHost]
  );

  // Create a ref for sessionId
  const sessionIdRef = useRef(sessionId);
  // Update the ref whenever sessionId changes to avoid closures scopes below.
  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  // Create a ref for botLanguage
  const botLanguageRef = useRef(botLanguage);
  // Update the ref whenever botLanguage changes to avoid closures scopes below.
  useEffect(() => {
    botLanguageRef.current = botLanguage;
  }, [botLanguage]);

  const requestParams = useMemo(() => {
    const chatbotUrlParams = chatbotParams ?? {};
    return {
      session_id: sessionIdRef.current,
      language: botLanguageRef.current,
      bot_enabled: botConfig?.chatbot?.hasOwnProperty("bot_enabled")
        ? botConfig.chatbot.bot_enabled
        : true,
      ...chatbotUrlParams,
    };
  }, [chatbotParams, sessionIdRef, botLanguageRef, botConfig?.chatbot]);

  // --------------------------------FUNCTIONS----------------
  const handleLiveUserConnect = async (id: string) => {
    if (!id) {
      return console.warn("session id not found");
    }

    if (liveChatConnected) {
      return console.warn("live chat already connected");
    }

    //detect if chat is within business hours
    const checkTimings = isWithinBusinessHour(botConfig.config.active_timings);

    const isChatWithInTime = checkTimings?.isChatWithInTime ?? true;
    const timelogs = checkTimings?.timelogs ?? {};

    const data = await generateConnectSocketToAgent(
      "Live chat",
      id,
      socket,
      timelogs
    );

    if (!isChatWithInTime) {
      handleOutsideBusinessTimeClick(id);
      return;
    }

    executeConnectSocketEventWithConnectUser(data);
  };

  const handleOutsideBusinessTimeClick = async (id: string) => {
    let message =
      botConfig.config?.active_timings?.outside_business_hours_message ??
      "Sorry, we are not available at this time. Please try again later.";
    const data = await generateOutsideHoursMessage(message, id, null);

    dispatch(setAllMessages([data]));

    dispatch(
      setLastNodeResponse({ input: false, input_type: null, session_id: id })
    );
  };

  const storeSessionId = (id: string) => {
    if (id === sessionIdRef.current) return;

    dispatch(setSessionId(id));
    dispatch(setIsSessionIdFromLocalStorage(false));

    //* do not store session id in local storage if widget preview type is getaWidgetPreview
    let tempId =
      widgetPreviewType !== PreviewType.getaWidgetPreview ? id : null;

    commService.postMessage({
      event_type: OEvents.CHATBOT_SESSION_ID,
      data: tempId,
    });
  };

  const handleNewMessages = async (data: any) => {
    if (!data) return;
    if (!Array.isArray(data)) return;

    dispatch(setTypingStatus(`${botName} is typing...`));
    for (let element of data) {
      await sleep(SLEEP_TIME);
      if (element?.["subType"] === "button") {
        let temp = {
          value: element?.["response"],
          type: "button",
          time: element?.["createdAt"],
        };
        dispatch(setAllMessages(temp));
      } else {
        dispatch(setAllMessages(element?.response));
      }
      dispatch(setLastNodeResponse(element));
      dispatch(setOriginalResponses(element));
    }

    //set typing status
    dispatch(setTypingStatus(``));
    //sound
    playSoundLastBotMsg();

    //store session id
    const newSessionId = data?.[data?.length - 1]?.session_id ?? "";
    storeSessionId(newSessionId);
  };

  const handleHistoryMessages = async (history: any) => {
    if (
      !history ||
      history?.chat_data?.length === 0 ||
      !Array.isArray(history?.chat_data)
    ) {
      console.warn("chatData not found");
      dispatch(setSessionId(null));
      dispatch(setIsSessionIdFromLocalStorage(false));
      if (directLiveChatEnabled) {
        getNewMessagesForDirectLivechat({
          ...requestParams,
          session_id: null,
          bot_enabled: false,
        });
      } else {
        getNewMessages({ ...requestParams, session_id: null });
      }
      return;
    }

    if (history?.user?.botid !== botId) {
      console.warn("bot id not matched");
      getNewMessages({ ...requestParams, session_id: null });
      return;
    }

    let payload: any = [];

    history?.chat_data?.forEach((item: any, index: any) => {
      if (item?.["user_query_object"]) {
        let temp = {
          ...item?.["user_query_object"],
          time: item?.["createdAt"],
        };
        payload.push(temp);
      }

      if (item?.["bot_response"]?.["response"]?.length > 0) {
        //single card
        if (item?.["bot_response"]?.["subType"] === "button") {
          let temp = {
            value: item?.["bot_response"]?.["response"],
            type: "button",
            time: item?.["createdAt"],
          };
          payload.push(temp);
        } else {
          item?.["bot_response"]?.["response"]?.forEach((curRes: any) => {
            let temp = {
              ...curRes,
              time: item?.["createdAt"],
            };
            payload.push(temp);
          });
        }
      }

      if (index + 1 === history?.chat_data?.length) {
        dispatch(
          setLastNodeResponse(
            item?.["user_query_object"] ?? item?.["bot_response"]
          )
        );
      }

      dispatch(setOriginalResponses(item?.["bot_response"]));
    });

    dispatch(setAllHistoryMessages(payload));
    dispatch(setIsSessionIdFromLocalStorage(true));

    if (history?.user?.agent?.length > 0) {
      console.error("agent found");
      executeConnectSocketEventWithReConnectUser();
    }

    if (
      history?.user?.conversation_type === "livechat" &&
      !history?.user?.agent
    ) {
      dispatch(setAllHistoryMessages([]));
      if (directLiveChatEnabled) {
        getNewMessagesForDirectLivechat({
          ...requestParams,
          session_id: null,
          bot_enabled: false,
        });
      } else {
        getNewMessages({ ...requestParams, session_id: null });
      }
    }
    await sleep(500);
    setLoading(false);
  };

  // --------------------------------API CALLS------------------

  //================= GET BOT MESSAGES =================
  const getNewMessagesForDirectLivechat = (request: any) => {
    return new Promise((resolve, reject) => {
      ConversationService.getMessagesDirectLivechat(request, botHeaders)
        .then(async (response) => {
          handleNewMessages(response?.data);

          const res = response?.data;
          const newSessionId = res?.[res?.length - 1]?.session_id ?? "";
          setTimeout(() => {
            handleLiveUserConnect(newSessionId);
          }, 1000);

          resolve(response);
        })
        .catch((error) => {
          console.error("getNewMessagesForDirectLivechat api:", error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  //================= GET BOT MESSAGES =================
  const getNewMessages = (request: any) => {
    return new Promise((resolve, reject) => {
      ConversationService.getMessages(request, botHeaders, widgetPreviewType)
        .then(async (response) => {
          handleNewMessages(response?.data);
          resolve(response);
        })
        .catch((error) => {
          console.error("get messages api:", error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  //================= GET BOT HISTORY MESSAGES =================
  const getHistoryMessages = async (request: any) => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      ConversationService.getHistoryMessages(request, botHeaders)
        .then(async (response) => {
          handleHistoryMessages(response?.data?.data);
          resolve(response);
        })
        .catch((error) => {
          console.error("history get messages api:", error);
          reject(error);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  //================= POST BOT USER QUERY =================
  const postBotUserQuery = (data: any) => {
    dispatch(setTypingStatus(`${botName} is typing...`));
    ConversationService.postMessages(data, botHeaders, widgetPreviewType)
      .then(async (response) => {
        handleNewMessages(response?.data?.payload);
      })
      .catch((error) => {
        console.error("message not sent:", error);
        dispatch(removeMessage(data));
      })
      .finally(() => {});
  };

  //================= PLAY SOUND =================
  const playSoundLastBotMsg = useCallback(() => {
    if (botConfig.config?.message_tone?.enabled) {
      audio.volume = (botConfig.config?.message_tone?.volume_level || 70) / 100;
      audio.play();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    postBotUserQuery,
    getHistoryMessages,
    getNewMessages,
    getNewMessagesForDirectLivechat,
    loading,
    requestParams,
  };
};

export default useMessagesData;
