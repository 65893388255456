import React, { memo, useEffect, useMemo, useRef } from "react";

import { useSelector } from "react-redux";
import {
  StyledBotContainer,
  StyledFloatingButtonWrap,
} from "../Styles/StyledScreens";

import { HostWindowCommService } from "../../Services/IframeHostService/HostWindowCommService";
import { IEvents, OEvents } from "../../Services/IframeHostService/models";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import {
  botInfoStore,
  setIsOpenedFirstTime,
  setOpenStatus,
  setBotToFullScreen,
} from "../../Store/Slices/socket/BotInfoSlice";
import {
  deviceSizeStore,
  setDeviceSize,
} from "../../Store/Slices/socket/DeviceSizeSlice";

import MainUI from "../../socket/screens/MainUI";
import useSocket from "../hooks/useSocket";
import { PreviewType } from "../utils/Enum";
import CalloutMessageScreen from "./CalloutMessageScreen";
import SpeedDialButton from "./SpeedDialButton";
import {
  setExecuteConnectSocketEventWithConnectUser,
  setExecuteConnectSocketEventWithReConnectUser,
  socketStatusStore
} from "../../Store/Slices/socket/SocketSlice";
import useTimer from "../hooks/useTimer";
import {getWidgetPositionConfig } from "../utils/utils";

interface Props { }

const Home: React.FC<Props> = () => {
  const containerRef = useRef<any>(null);
  const floatingButtonRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const { timer } = useTimer(1500);
  const { botConfig } = useAppSelector(useBotConfigStore);
  const IframeDimensions = useSelector(deviceSizeStore);
  const [fullscreen, setFullscreen] = React.useState<boolean>(false);
  const { chatOpened, isOpenedFirstTime, widgetPreviewType, isBotSetToFullScreen, } =
    useSelector(botInfoStore);
  //* do not remove this import
  const { socket } = useAppSelector(socketStatusStore);
  const { height, width } =
    useSelector(deviceSizeStore);

  const [clientViewPortSize, setClientViewPortSize] = React.useState<any>(0);

  let commService = useMemo(() => {
    return HostWindowCommService.getInstance();
  }, []);

  const chatbotAutoOpen = useMemo(
    () => botConfig?.config?.always_open ?? botConfig?.config?.auto_open ?? false,
    [botConfig]
  );

  // Resize event listener
  useEffect(() => {
    const handleParentWindowResize = (event: any) => {
      const viewportSize = event?.data?.data?.viewportSize;
      setClientViewPortSize(event?.data?.data?.viewportSize);

      let newHeight;

      if (viewportSize?.width < 768) {
        newHeight = viewportSize?.height;
      } else {
        if (viewportSize?.height < 500) {
          newHeight = viewportSize?.height;
        } else {
          newHeight =
            viewportSize?.height > 860 ? 751 : viewportSize?.height - 100
        }

        // newHeight = viewportSize?.height > 860 ? 751 : viewportSize?.height - 100;
      }

      let temp = {
        width: viewportSize?.width > 768 ? 376 : viewportSize?.width,
        height: newHeight,
      };

      dispatch(setDeviceSize(temp));
    };

    // Register the event listener when the component mounts
    commService.registerEventListener(
      IEvents.PARENT_WINDOW_RESIZE,
      handleParentWindowResize
    );
  });

  const handleQuickChat = () => {
    commService.postMessage({
      event_type: OEvents.CHATBOT_OPEN,
      data: { open: chatOpened ? true : false },
    });
    dispatch(setOpenStatus(!chatOpened));

    if (!isOpenedFirstTime) {
      dispatch(setIsOpenedFirstTime(true));
    }
  };

  const frameWidth = useMemo(() => {
    return botConfig?.config?.custom_width ? botConfig?.config?.custom_width : width
   }, [width,botConfig]);

  const frameHeight = useMemo(() => {
    const autoHeight = botConfig?.config?.auto_height ?? false;
    const modifiedFinalValue = height - (botConfig?.config?.auto_height_limiting_number ?? 0); 
    const maxHeight = autoHeight ? modifiedFinalValue : 610;
    return maxHeight ?? 610;
   }, [height,botConfig]);



  const handleOpenStateIframeDimensions = () => {
    const wh2 = {
      width: frameWidth - 16,
      height: frameHeight - 8,
      state: "open",
    };
    commService.postMessage({
      event_type: OEvents.CHATBOT_RESIZE,
      data: wh2,
    });
  };

  useEffect(() => {
    if (chatOpened) {
      handleOpenStateIframeDimensions();
    }
  }, [clientViewPortSize, chatOpened]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClosedStateIframeDimensions = () => {
    if (floatingButtonRef?.current) {
      let { width: closedDivWidth, height: closedDivHeight } =
        floatingButtonRef.current.getBoundingClientRect();

      let newCalculatedWidth = closedDivWidth + 16 > 80 ? floatingButtonRef.current.offsetWidth : 80;
      let newCalculatedHeight =
        closedDivHeight + 16 > 84 ? closedDivHeight : 80;

      setTimeout(() => {
        const wh = {
          width: newCalculatedWidth,
          height: newCalculatedHeight,
          state: "close",
        };
        commService.postMessage({
          event_type: OEvents.CHATBOT_RESIZE,
          data: wh,
        });
      }, 100);
    }
  };

  useEffect(() => {
    if (chatOpened && !isOpenedFirstTime) {
      dispatch(setIsOpenedFirstTime(true));
    }
    if (chatOpened) {
      handleOpenStateIframeDimensions();
    } else {
      handleClosedStateIframeDimensions();
    }
  }, [chatOpened, isOpenedFirstTime]); // eslint-disable-line react-hooks/exhaustive-deps

  const { disconnectSocketEvent, connectSocketEventWithReConnectUser, connectSocketEventWithConnectUser } = useSocket();

  useEffect(() => {
    // connectSocketEvent();
    dispatch(setExecuteConnectSocketEventWithReConnectUser(connectSocketEventWithReConnectUser));
    //@ts-ignore
    dispatch(setExecuteConnectSocketEventWithConnectUser(connectSocketEventWithConnectUser));
    return () => {
      disconnectSocketEvent();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (
      widgetPreviewType === PreviewType.getaWidgetPreview || chatbotAutoOpen
    ) {
      setTimeout(() => {
        dispatch(setOpenStatus(true));
        
      }, 100); // DO NOT remove settimeout //
    }
  }, [widgetPreviewType]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isBotSetToFullScreen) {
      setTimeout(() => {
        dispatch(setBotToFullScreen(true));
        dispatch(setOpenStatus(true));
        setFullscreen(true);
      }, 100);
    }
  }, [isBotSetToFullScreen]); // eslint-disable-line react-hooks/exhaustive-deps

  const widgetPositionConfig = useMemo(() => { 
    return getWidgetPositionConfig({config:botConfig.config, IframeDimensions:IframeDimensions});
  }, [IframeDimensions, botConfig]);

  if (timer > 0) return null;

  return (
    <StyledBotContainer
      devicewidth={frameWidth}
      isFullScreen={fullscreen}
      deviceheight={frameHeight}
      chatopened={chatOpened ? 1 : 0}
      id="bot-container"
      ref={containerRef}
      WIDGET_POSITION={widgetPositionConfig}
      isTooltipVisible={!isOpenedFirstTime}
    >
      <MainUI isFullScreen={fullscreen} />

      <StyledFloatingButtonWrap
        chatopened={chatOpened ? 1 : 0}
        tooltipPosition={botConfig.config?.tooltip?.position}
        WIDGET_POSITION={widgetPositionConfig}
        isTooltipVisible={isOpenedFirstTime}
        ref={floatingButtonRef}
        hideFloatingButton={botConfig.config?.hide_floating_Button ?? false}
      >
        {botConfig.config?.tooltip?.enabled &&
          !chatOpened &&
          !isOpenedFirstTime ? (
          <CalloutMessageScreen
            handleQuickChat={handleQuickChat}
            handleClosedStateIframeDimensions={
              handleClosedStateIframeDimensions
            }
          />
        ) : null}
        <SpeedDialButton handleQuickChat={handleQuickChat} />
      </StyledFloatingButtonWrap>
    </StyledBotContainer>
  );
};

export default memo(Home);
