export interface ISocketConnectionTypes {
  SEND_MESSAGE: string;
  AGENT_CHAT: string;
  RECEIVE_MESSAGE: string;
  RECEIVE_MESSAGE_HISTORY: string;
  JOIN_ROOM: string;
  CONNECT: string;
  CONNECT_ERROR: string;
  CONNECT_TIMEOUT: string;
  RECONNECT: string;
  DISCONNECT: string;
  AGENT_CONNECT: string;
  AGENT_RESPONSE: string;
  USER_RESPONSE: string;
  BOT_CONVERSATION: string;
  BOT_CONVERSATION_PREVIEW: string;
  BOT_CONVERSATION_HISTORY: string;
  TYPING_STATUS: string;
  SET_TYPING_STATUS: string;
  INITIATE_BOT_SESSION: string;
  GET_SESSION_ID: string;
  USER_CONNECT: string;
  USER_CONNECT_RESPONSE: string;
  USER_RECONNECT: string;
  USER_RECONNECT_RESPONSE: string;
  CHAT_AUTO_CLOSE: string;
  CHAT_END_BY_USER: string;
}

export const SOCKET_CONNECTION_TYPES: ISocketConnectionTypes = {
  SEND_MESSAGE: "user-response",
  AGENT_CHAT: "agent-chat",
  RECEIVE_MESSAGE: "bot-response",
  RECEIVE_MESSAGE_HISTORY: "bot-response-history",
  JOIN_ROOM: "join_room",
  CONNECT: "connect",
  CONNECT_ERROR: "connect_error",
  CONNECT_TIMEOUT: "connect_timeout",
  RECONNECT: "reconnect",
  DISCONNECT: "disconnect",
  AGENT_CONNECT: "agent-connect",
  AGENT_RESPONSE: "live_agent_response",
  USER_RESPONSE: "live_user_response",
  BOT_CONVERSATION: "bot-conversation",
  BOT_CONVERSATION_PREVIEW: "bot-conversation-preview",
  BOT_CONVERSATION_HISTORY: "get-history",
  TYPING_STATUS: "typing-status",
  SET_TYPING_STATUS: "set-typing-status",
  INITIATE_BOT_SESSION: "intiate-bot",
  GET_SESSION_ID: "get-session",
  USER_CONNECT: "user-connect",
  USER_CONNECT_RESPONSE: "user-connect-response",
  USER_RECONNECT: "user-reconnect",
  USER_RECONNECT_RESPONSE: "user-reconnect-response",
  CHAT_AUTO_CLOSE: "chat-auto-close",
  CHAT_END_BY_USER: "chat-end-by-user",
  
};

export enum MESSAGE_TYPES {
  ATTACHMENT = "attachment",
  TEXT = "text",
  FILE = "file",
  IMAGE = "image",
  VIDEO = "video",
  AUDIO = "audio",
  LINK = "link",
  MOBILE = "mobile",
  DOCUMENT = "document",
  EMAIL = "email",
  CAROUSEL = "carousel",
  BUTTONS = "buttons",
  CHOICE = "choice",
  FLOW = "flow",
  FEEDBACK = "feedback",
  LIVE_AGENT_REQUESTED = "live_agent",
  TIMESTAMPS = "timestamps",
  ALERT = "alert",
  AWAY = "AWAY",
  TRANSFER_TO_FAQS = "TRANSFER_TO_FAQS",
  INFO = "info",
  BUTTON = "button",
  PRODUCT_CAROUSEL = "product_carousel",
}

export enum INPUT_TYPES {
  NAME = "NAME",
  MOBILE = "MOBILE",
  WEBSITE = "WEBSITE",
  EMAIL = "EMAIL",
  QUERY = "QUERY",
  AWAY = "AWAY",
  NONE = "NONE",
  FILE = "FILE",
  FAQ = "FAQ",
  TRANSFER_TO_FAQS = "TRANSFER_TO_FAQS",
  OTP = "OTP",
  NUMBER = "NUMBER",
  DATE = "DATE",
  FEEDBACK = "FEEDBACK",
  TEXT = "TEXT",
  DATETIME = "DATETIME",
  DROPDOWN = "DROPDOWN",
  FILE_UPLOAD = "FILE_UPLOAD",
  CUSTOM = "CUSTOM",
  LIVECHAT = "LIVECHAT",
  ALPHANUMERIC = "ALPHANUMERIC",
}

export enum CHOICE_TYPES {
  CLOSE_SESSION = "close_session",
  RESTART_SESSION = "restart_session",
  NAVIGATE_TO_PAGE = "navigate_to_page",
  STAY_ON_PAGE = "stay_on_page",
}

export enum WIDGET_POSITIONS_OPTIONS {
  LEFT = "Left",
  CENTER = "Center",
  RIGHT = "Right",
}
export enum TOOLTIP_POSITIONS_OPTIONS {
  TOP = "Top",
  SIDE = "Side",
}

export enum PreviewType {
  getaWidgetPreview = "getaWidgetPreview",
}
