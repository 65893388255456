export const processHTMLLinks = (htmlString: any) => {
    if (!htmlString) return "";
  
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
  
    const links = tempDiv?.getElementsByTagName("a");
    for (let i = 0; i < links?.length; i++) {
      links[i]?.setAttribute("target", "_blank");
      links[i]?.setAttribute("rel", "noopener noreferrer");
    }
  
    return tempDiv?.innerHTML ?? "";
  };