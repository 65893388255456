import { Slide } from "@mui/material";
import React, { memo } from "react";
import { useAppSelector } from "../../Store";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import { QuickChatIcon } from "../Styles/StyledScreens";
import { AppMaterialIcons } from "../appComponents/Icons";
import { BotConfigDefaults } from "../utils/defaults";

interface Props {
  handleQuickChat: any;
}

const SpeedDialButton: React.FC<Props> = ({ handleQuickChat }) => {
  const { botConfig } = useAppSelector(useBotConfigStore);
  const { chatOpened } = useAppSelector(botInfoStore);

  return (
    <Slide
      direction={"up"}
      in={true}
      unmountOnExit
      timeout={{ enter: 600, exit: 500 }}
    >
      <QuickChatIcon
        onClick={() => {
          handleQuickChat();
        }}
        disableRipple={true}
        disableFocusRipple={true}
        className="QuickChatIcon"
      >
        {chatOpened ? (
          <AppMaterialIcons
            iconName="ExpandMore"
            style={{
              fontSize: "32px",
            }}
          />
        ) : (
          <img
            src={
              botConfig.config?.company_logo?.source ||
              BotConfigDefaults.CHAT_AVATAR
            }
            alt="Support chat"
            className="styledChatLogo"
          />
        )}
      </QuickChatIcon>
    </Slide>
  );
};

export default memo(SpeedDialButton);
