import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

interface Props {
  chatOpened: boolean;
  botId: string | any;
  location: string | any;
  getaHost: string | any;
  widgetPreviewType: string | any;
  visitorId: string | any;
  isOpenedFirstTime: boolean;
  deviceSize: { width: number; height: number };
  toggleReloadWidget: boolean;
  isBotSetToFullScreen: boolean;
  chatbotParams: Record<string, string> | null;
}

let INITIAL_STATE: Props = {
  chatOpened: false,
  botId: "",
  location: "",
  widgetPreviewType: null,
  visitorId: null,
  isOpenedFirstTime: false,
  deviceSize: {
    width: 0,
    height: 0,
  },
  getaHost: null,
  toggleReloadWidget: false,
  isBotSetToFullScreen: false,
  chatbotParams: null,
};

const BotInfoSlice = createSlice({
  name: "BotInfoSlice",
  initialState: INITIAL_STATE,
  reducers: {
    setOpenStatus(state, { payload }: PayloadAction<any>) {
      state.chatOpened = payload ? true : false;
    },

    setBotId(state, { payload }: PayloadAction<any>) {
      state.botId = payload;
    },
    setLocation(state, { payload }: PayloadAction<any>) {
      state.location = payload;
    },

    setWidgetPreviewType(state, { payload }: PayloadAction<any>) {
      state.widgetPreviewType = payload;
    },

    setIsOpenedFirstTime(state, { payload }: PayloadAction<any>) {
      state.isOpenedFirstTime = payload;
    },
    setVisitorId(state, { payload }: PayloadAction<any>) {
      state.visitorId = payload;
    },
    setToggleReloadWidget(state, { payload }: PayloadAction<any>) {
      state.toggleReloadWidget = payload;
    },
    setGetaHost(state, { payload }: PayloadAction<any>) {
      state.getaHost = payload;
    },
    setBotToFullScreen(state, { payload }: PayloadAction<any>) {
      state.isBotSetToFullScreen = payload;
    },
    setChatbotParams(state, { payload }: PayloadAction<any>) {
      state.chatbotParams = payload;
    }
  },
});

export default BotInfoSlice.reducer;

export const {
  setOpenStatus,
  setBotId,
  setLocation,
  setWidgetPreviewType,
  setIsOpenedFirstTime,
  setVisitorId,
  setGetaHost,
  setToggleReloadWidget,
  setBotToFullScreen,
  setChatbotParams
} = BotInfoSlice.actions;

export const botInfoStore = (state: RootState) => state.botData.currrentBotInfo;
