import { IconButton, Tooltip } from "@mui/material";
import React, { useMemo, useState } from "react";
import "slick-carousel/slick/slick.css";
import { ProductStyledSlickSlider, ProductWidgetSliderBox, WidgetSliderBox } from "../..//Styles/StyledComponents";
import { StyledSlickSlider } from "../../Styles/StyledComponents";

import { MESSAGE_TYPES } from "../../utils/Enum";
import { AppMaterialIcons } from "../Icons";
import AppMessageBoxWrapper from "../UtilsComponents/AppMessageBoxWrapper";
import AppRenderNestedFlowButtons from "./nestedFlow/AppRenderNestedFlowButtons";
import AppRenderNestedCtaButtons from "./nestedFlow/AppRenderNestedCtaButtons";
import AppRenderNestedLiveButtons from "./nestedFlow/AppRenderNestedLiveButtons";
import { useSelector } from "react-redux";
import { botInfoStore } from "../../../Store/Slices/socket/BotInfoSlice";
import { processHTMLLinks } from "../../utils/processtext";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const FallbackImage = require("../../../assets/images/Image_not_available.png");

const next_prev_style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "40px",
  height: "40px",
  border: "1px solid #e6e4e2",
  borderRadius: "50%",
  color: "#676767",
  backgroundColor: "#fff",
  boxShadow: "0 0 8px 0 rgba(0, 0, 0, 10%)",
  transition: "all .15s ease-out",
  cursor: "pointer",
  opacity: 1,
  top: '50%'
};

interface SliderProps {
  userMessage: boolean;
  items: any[];
  time: string;
  readStatus?: "sent" | "delivered" | "seen";
  [otherProps: string]: any;
}

const AppRenderSlider = React.memo(
  ({
    userMessage = false,
    items,
    time,
    readStatus = "delivered",
    ...otherProps
  }: SliderProps) => {
    // This commented code is for anchor tag to open in new window
    // const processedTexts = useMemo(() => 
    //   items?.map(item => ({
    //     ...item,
    //     processedText: processHTMLLinks(item?.text ?? null)
    //   })),
    //   [items]
    // );
    const { isBotSetToFullScreen } = useSelector(botInfoStore);
    const [currentSlide, setCurrentSlide] = useState(0);
    return (
      <AppMessageBoxWrapper
        userMessage={userMessage}
        time={time}
        isMedia={true}
        readStatus={readStatus}
        divStyle={{
          flexWrap: "nowrap",
          // minWidth: "50%",
          // maxWidth: "300px",
          backgroundColor: "white",
        }}
      >
        <ProductStyledSlickSlider 
        {...settings}
          beforeChange={(oldIndex: any, newIndex: any) => setCurrentSlide(newIndex)}
          slidesToShow={items.length > 1 ? 1.1 : 1} // Ensure correct handling for single-item cases
          nextArrow={<CustomNextArrow disabled={currentSlide >= items.length - 1.1} />}
          prevArrow={<CustomPrevArrow disabled={currentSlide <= 0} />}
        >
          {items?.length &&
            items?.map((item: any, index: number) => {
              return (
                <ProductWidgetSliderBox
                  key={index.toString()}
                  isFullScreen={isBotSetToFullScreen}
                >
                  {item?.image ? (<div className="image-wrapper">

                    <a href={item?.image} target="_blank" rel="noreferrer">
                      <img
                        className="productImage"
                        src={item?.image}
                        alt={"image " + (index + 1)}
                        // onClick={() => window.open(item?.image, "_blank")}
                        onError={(error: any) => {
                          error.target.src = FallbackImage;
                        }}
                        />
                    </a>
                        </div>
                  ) : null}
<Tooltip 
                    title={item?.title ? item?.title : ''} 
                    arrow 
                    placement="top"
                  >

                  <p
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item?.title ? item?.title : null,
                    }}
                    />
                    </Tooltip>

<Tooltip 
                    title={item?.subTitle ? item?.subTitle : ''} 
                    arrow 
                    placement="top"
                  >

                  <p
                    className="subTitle"
                    dangerouslySetInnerHTML={{
                      __html: item?.subTitle ? item?.subTitle : null,
                    }}
                    />
                    </Tooltip>
                   <Tooltip 
                    title={item?.text ? item.text.replace(/<[^>]*>/g, '') : ''} 
                    arrow 
                    placement="top"
                  >

                  <p
                    className="product_description"
                    dangerouslySetInnerHTML={{
                      __html: item?.text ? item?.text : null,
                    }}
                    />
                    </Tooltip>

                  <div className="btnWrapper">
                    {item?.buttons?.map((curBtn: any, index: number) => {
                      //* FLOW BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.FLOW) {
                        return (
                          <AppRenderNestedFlowButtons
                            key={index.toString()}
                            userMessage={userMessage}
                            item={curBtn}
                            time={time}
                          />
                        );
                      }
                      //* CTA BUTTONS
                      if (
                        curBtn?.type === MESSAGE_TYPES.LINK ||
                        curBtn?.type === MESSAGE_TYPES.MOBILE ||
                        curBtn?.type === MESSAGE_TYPES.EMAIL
                      ) {
                        return (
                          <AppRenderNestedCtaButtons
                            item={curBtn}
                            key={index.toString()}
                            time={time}
                          />
                        );
                      }

                      //* LIVE BUTTONS
                      if (curBtn?.type === MESSAGE_TYPES.LIVE_AGENT_REQUESTED) {
                        return (
                          <AppRenderNestedLiveButtons
                            key={index.toString()}
                            item={curBtn}
                            time={time}
                          />
                        );
                      }

                      return null;
                    })}
                  </div>
                </ProductWidgetSliderBox>
              );
            })}
        </ProductStyledSlickSlider>
      </AppMessageBoxWrapper>
    );
  }
);

export default AppRenderSlider;


const CustomNextArrow = ({ disabled, ...props }: { disabled: boolean; className?: string; style?: any; onClick?: () => void }) => (
  <IconButton
    {...props}
    onClick={!disabled ? props.onClick : undefined}
    className={`${props.className} nextPreBtn`}
    style={{
      ...props.style,
      ...next_prev_style,
      opacity: disabled ? 0 : 1,
      cursor: disabled ? "not-allowed" : "pointer",
    }}
    disabled={disabled}
  >
    <ChevronRightIcon />
  </IconButton>
);

const CustomPrevArrow = ({ disabled, ...props }: { disabled: boolean; className?: string; style?: any; onClick?: () => void }) => (
  <IconButton
    {...props}
    onClick={!disabled ? props.onClick : undefined}
    className={`${props.className} nextPreBtn`}
    style={{
      ...props.style,
      ...next_prev_style,
      opacity: disabled ? 0 : 1,
      cursor: disabled ? "not-allowed" : "pointer",
    }}
    disabled={disabled}
  >
    <AppMaterialIcons iconName={"ChevronLeft"} />
  </IconButton>
);

const settings = {
  className: "slickSlider",
  lazyLoad: true,
  infinite: false,
  slidesToScroll: 1,
  swipeToSlide: true,
  pauseOnHover: true,
};